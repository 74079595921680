<template>
</template>

<script>
import common from "../js/common"

export default {  
    data() {
        return {
            lockJsUrl: 'https://cdn.auth0.com/js/lock/11.20/lock.min.js',
            clientId: 'w2srutR1gY60eYT7GvaqW1UQ84csDBlJ',
            domain: 'login.neilyoungarchives.com',
            configurationBaseUrl: 'https://cdn.auth0.com',
            state: (new Date()).valueOf(),
            scope: 'openid email offline_access',
            redirectUrl: 'https://connect.neilyoungarchives.com/auth0/callback',
            responseType: 'code',
            lock: null,
            validating: false,
        }
    },
    methods:{
        setup() {
            this.lock = new Auth0Lock(this.clientId, this.domain, {
                configurationBaseUrl: this.configurationBaseUrl,
                auth: {
                    redirectUrl: this.redirectUrl,
                    responseType: this.responseType,
                    params: {
                        scope: this.scope,
                        state: 'dashboard-' + this.state
                    },
                },                
                theme: {
                    logo: '',
                    primaryColor: '#967a5c',
                    languageDictionary: {
                        title: ' '
                    }                    
                },
                closable: true,
                allowSignUp: false,
                allowShowPassword: true
            })
        },
        async validate() {
            if (this.validating) return

            if (!window.token) 
                return evm.$emit('openAuth0')

            this.validating = true

            setTimeout(() => {
                this.validating = false
            }, 600 * 1000)

            const url = `${apiUrl}/user/validate`
            const res = await f7.request.promise.json(url, {
                token: window.token
            }).catch(e => {})
            
            if (!res) return 

            const { status, user } = res.data
            if (status == 'error') {
                f7.dialog.alert('Please log in again as your session has expired')
                evm.$emit('openAuth0')

                delete localStorage.token
                window.token = null
                return
            }

            if (!user || !user.admin) {
                f7.dialog.alert('Unauthorized access')                        
                evm.$emit('openAuth0')

                delete localStorage.token
                window.token = null
                return 
            }

            window.token = localStorage.token = user.token
            evm.$emit('loginStatus', user)
            // refresh view after logging in
        },      
    },
    mounted () {    
        setTimeout(() => {
            f7.loadJs(this.lockJsUrl).then(() => {
                if (!this.lock) this.setup()

                let token = common.getQuery(window.location.search, 'token')
                if (token) window.token = token

                this.validate()            
                window.onfocus = this.validate                    
            })
        }, 1)

        evm.$on('openAuth0', () => {
            this.lock.show()
            $$('.auth0-lock-header-bg').css({'background': 'url(static/img/header.png)'})
        })

        evm.$on('userLogOut', () => {
            f7.addNotification({
                title: 'See you soon!',
                message: 'You are now logged out.',
                hold: 3000
            })                   

            delete localStorage.token
            evm.$emit('openAuth0')
        })        
    },
    computed: {
    }
}
</script>

<style lang='less'>
//@import "../css/app.less";

.auth0-lock-header, .auth0-lock-header-bg {
    height: 150px !important;
}
@media screen and (max-width: 480px) {
    .auth0-lock-widget {
        width: 100% !important;
    }  
}        
@media screen and (min-width: 481px) {
    .auth0-lock-widget {
        width: 375px !important;
    }  
}        
.auth0-lock-header-bg {
    background-repeat: no-repeat !important;
    background-size: cover !important 
}
.auth0-lock-name {
    display: none;
}
.auth0-lock-content, .auth0-lock-tabs, input {
    background-color: #f2ecdc;
}
.auth0-lock-input-wrap {
    border: 1px solid #ccc7b8 !important;
    background: #ccc7b8 !important;
}        
.auth0-lock-content-wrapper {
    background-color: #f2ecdc !important;
}                
.auth0-lock-header-bg-blur {
    display: none !important;
}
.auth0-lock-close-button {
    background: rgba(0,0,0, 0.2);
    svg {
        margin: initial;        
    }
}
</style>