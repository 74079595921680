<template>
	<div class="export">
		<f7-button class="report-button" raised fill text="Export" @click="exportReport"/>
	</div>
</template>
<script>
export default {
    props: {
    	exportReport: Function, 
    }
}
</script>
<style lang="less">
.export {
	height: 29px;
	margin-right: 30px;
	display: inline-flex;
	float: right;
	width: auto;
	.report-button {
		width: 80px;
		margin-left: 11px;
	}
}
</style>