<template>
<f7-app :params="f7params">
	<left-menu/>
	<f7-views tabs class="safe-areas">
		<f7-view v-for="v in views"
			:id="'view-' + v" 
			:name='v'  
			:key="v"
			tab :url="'/' + v"/>
	</f7-views>
	<auth0/>
</f7-app>
</template>
<script>
import routes from '../js/routes.js'
export default {
	data() {
		return {
			views: ['streams', 'downloads', 'users', 'devices', 'orders'],
			f7params: {
				name: 'NYA Dashboard',
				theme: 'ios', 
				routes: routes,
				view: {
						allowDuplicateUrls: true,
						animate: innerWidth < 600,  
						stackPages: true, 
						iosSwipeBack: false,
				},
				panel: {
					visibleBreakpoint: 960,
				},
			},
		}
	},
	methods: {
	},
	mounted() {
		this.$f7ready((f7) => {})
	}
}
</script>