import Vue from 'vue'
import F7, {Dom7} from 'framework7/framework7-lite.esm.bundle.js'
import F7Vue from 'framework7-vue/framework7-vue.esm.bundle.js'
import Router from './routes.js'

import 'framework7/css/framework7.bundle.css'
import '../css/icons.css'
import '../css/fonts.css'
import '../css/app.less'

import App from '../components/app.vue'
import LeftMenu from '../components/left-menu.vue'
import LeftMenuToggle from '../components/leftmenu-toggle.vue'
import Auth0 from '../components/auth0.vue'
import ReportsSearch from '../components/reports-search.vue'
import SearchResetButtons from '../components/search-reset-buttons.vue'
import Export from '../components/export.vue'

Vue.component('left-menu', LeftMenu)
Vue.component('leftmenu-toggle', LeftMenuToggle)
Vue.component('auth0', Auth0)
Vue.component('reports-search', ReportsSearch)
Vue.component('search-reset-buttons', SearchResetButtons)
Vue.component('export', Export)

F7.use(F7Vue)

window.$$ = Dom7
window.evm = new Vue()

window.vm = new Vue({
	el: '#app',
	render: (h) => h(App),
	components: {
		app: App
	},
})

window.f7 = window.vm.$f7
window.token = localStorage.token || ''

window.apiUrl = 'https://connect.neilyoungarchives.com/api'
//window.apiUrl = 'http://localhost/api'
window.connectUrl = 'https://connect.neilyoungarchives.com'
//window.connectUrl = 'http://localhost'
window.cdnUrl = 'https://cdn.neilyoungarchives.com'


f7.loadJs = function (src) {
    return new Promise((resolve) => {    
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src
        script.onload = resolve
        document.body.appendChild(script)
    })
}

f7.addNotification = (n) => {
    var _n = f7.notification.create({
        title: n.title,
        text: n.message,
        closeTimeout: n.hold,
        closeButton: true,
    })
    _n.open()
}