<template>
    <f7-page class="order-details">
        <f7-navbar title="Order" back-link="Back"/>
    	<f7-block-title>Details</f7-block-title>

	    <f7-list inline-labels>
	        <f7-list-input
				v-for="key in headers"
				:label="key.replace(rex, '$1$4 $2$3$5')"
				:key="key"
				:title="key"
				type="text"
				:value="order[key] ? order[key] : 'N/A'"
				readonly/>
		</f7-list>

		<f7-block-title v-if="albums.length">Albums</f7-block-title>
		<f7-list inline-labels v-if="albums.length"
			class="order-list">
	        <f7-list-item v-for="(album, i) in albums"
	        	:key="i"	        
	        	:title="album"/>
		</f7-list>	

		<f7-block-title v-show="tracks.length">Tracks</f7-block-title>
		<f7-list inline-labels v-show="tracks.length"
			class="order-list">
	        <f7-list-item v-for="(track, i) in tracks"
	        	:key="i"
	        	:title="track"/>
		</f7-list>	
    </f7-page>
</template>

<script>
import common from '../js/common'
	export default {
		data() {
			return {
				headers: [
					'created', 'userId', 'userEmail', 'country', 
					'originalAmount', 'discountAmount', 'transactionId'
				],
				albums: [],
				tracks: [],
				rex: /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
			}
		},
		methods: {	
			getAlbums() {
				if (this.order.albums.length)
					this.albums = this.order.albums.split(',')
			},
			getTracks() {
				if (this.order.tracks.length)
					this.tracks = this.order.tracks.split(',')
			},	
			
		},
		mounted() {
			this.getAlbums()
			this.getTracks()
		},
		computed: {
		    order() {
		        return this.$f7route.context
		    },
		}
	}
</script>
<style lang="less">
@import "../css/common.less";
.order-details {
}
</style>