<template>
	<div class="search-reset-buttons" v-if="dateRange.length || email">
		<f7-row class="search-reset-buttons-group">
			<f7-button fill text="Search" @click="search"/>
			<f7-button class="cancel" fill text="Reset" @click="cancel" color="gray"
				:disabled="!dateRange.length && !email ? true : false" />
		</f7-row>
	</div>
</template>
<script>
export default {
    props: {
        search: Function,
        cancel: Function,
        dateRange: Array,
        email: String,
    },
}
</script>