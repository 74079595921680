import Downloads from '../pages/downloads.vue'
import Streams from '../pages/streams.vue'
import Users from '../pages/users.vue'
import Devices from '../pages/devices.vue'
import Orders from '../pages/orders.vue'
import OrderDetails from '../pages/order-details.vue'
import NotFoundPage from '../pages/404.vue'

var routes = [
	{
		name: 'downloads',		
		path: '/downloads',
		component: Downloads,
	},
	{
		name: 'streams',		
		path: '/streams',
		component: Streams,
	},
	{
		name: 'users',		
		path: '/users',
		component: Users,
	},
	{
		name: 'devices',		
		path: '/devices',
		component: Devices,
	},
	{
		name: 'orders',		
		path: '/orders',
		component: Orders,
	},
	{
		name: 'order-details',
		path: '/order-details',
		component: OrderDetails,
	},
	{
		path: '(.*)',
		component: NotFoundPage,
	},
]

export default routes