<template>
  	<f7-page class="orders" 
  		infinite 
		@infinite="onInfinite"
		:infinite-distance="100">
		<f7-navbar title="Orders" large
			:subtitle="subtitle">
 			<leftmenu-toggle slot="nav-left"/>
		</f7-navbar>
		<f7-block class="search">
			<f7-block-title>Search</f7-block-title>
			<reports-search 
				searchOption
				:searchType="searchType" 
				:onSearchType="onSearchType" 
				:searchBy="searchBy"
				:onEmail="onEmail" 
				:email="email"			
				:onDateRange="onDateRange" 
				:dateRange="dateRange" 
				:calendarParams="calendarParams"/>
			<search-reset-buttons 
				:email="email" 			
				:dateRange="dateRange"
				:search="search" :cancel="cancel"/>

			<export v-if="vlData.items.length"
				:exportReport="exportReport"/>
		</f7-block>		
		<div class="reports-container" v-show="vlData.items.length">
			<f7-list class="table-header">
				<f7-list-item>
					<f7-list-item-cell 
						v-for="(value, index) in headersRegex"
						:key="index"> {{ value }}
					</f7-list-item-cell>
				</f7-list-item>
			</f7-list>
			<f7-list virtual-list>
				<ul :style="`top: ${vlData.topPosition}px`">
					<f7-list-item v-for="item in vlData.items"
						link="#" :key="item.key" :data-index="item.key"
						@click="selectOrder(item, $event)">
						<f7-list-item-cell 
							v-for="(key, i) in headers"
							:key="i"> {{ item[key] }}
						</f7-list-item-cell>
					</f7-list-item>
				</ul>
			</f7-list>			
		</div>
  	</f7-page>
</template>

<script>
import common from '../js/common'
	export default {
		data() {
			return {
				headers: [
					'created', 'id', 'userId', 'userEmail', 'country', 'originalAmount',
					'discountAmount', 'totalAmount', 'transactionId', 'albums', 'tracks'
				],
				headersRegex: [],
				searchBy: '',
        		searchType: ['date', 'user email'],
        		email: '',        						
				start: '',
				end: '',
				vlData: {
          			items: [],
        		},
        		loading: false,
        		totalCount: 0,
        		vlCount: 0,		
				page: 1,
        		dateRange: [],
        		calendarParams: {
					dateFormat: 'dd-MM-yyyy', 
					rangePicker: true,
					routableModals: false,
				},	
				exportTypes: ['CSV', 'XLS'],
				exportType: '',				
			}
		},
		methods: {
			regexHeaders() {
				let header = ''
				this.headers.forEach((h) => {
					header = common.camelCaseRegex(h)
					this.headersRegex.push(header)
				})
			},
			onSearchType(e) {
				this.cancel()
				this.searchBy = e.target.value			
			},
			onEmail(e) {
				this.email = e.target.value.trim().toLowerCase()
			},			
			onDateRange(range) {
				if (range.length < 2) return

				this.dateRange = range
				this.start = range[0].toLocaleDateString('sv-SE')
				this.end = range[1].toLocaleDateString('sv-SE')
				this.resetVl()
			},
			search() {
				f7.dialog.preloader()
				this.resetVl()
				this.get()
			},
			cancel() {
				this.resetVl()
				this.dateRange = []
				this.start = ''
				this.end = ''
				this.email = ''
				this.searchBy = ''				
			},
			selectOrder(order, e) {
				f7.views.orders.router.navigate('/order-details/', { context: order })
			},
			async exportReport() {
				let url = `${apiUrl}/orders/aggregate/export?&token=${token}`

				if (this.searchBy == 'date')
					url += `&start=${this.start}&end=${this.end}`

				if (this.searchBy == 'user email') 
					url += `&userEmail=${this.email}`

				window.open(url, '_parent')
			},
			resetVl() {
				this.page = 1
				this.totalCount = 0
				this.vlCount = 0
				this.vlData.items = []
				this.vlData.topPosition = 0				
			},			
			updateVl(items) {
				if (!this.vl) {
					this.vl = f7.virtualList.create({
						el: $$(this.$el).find('.virtual-list'),
						items: [],
						height: 38,
	                    renderExternal: (vl, data) => {
	                    	this.vlData = data
	                    	vl.$ul.css({height: ''})
	                    }
					})
				}				

				items.forEach((item, i) => item.key = i + this.vlCount)					
	
				if (!this.vlCount)
					this.vl.replaceAllItems(items)
				else 
					this.vl.appendItems(items)
				
				this.vlCount += items.length

	           	setTimeout(() => {             
	                let h = this.vlCount * 38 + 200
	                $$(this.$el).find('.virtual-list').css({height: `${h}px`})
	            }, 1)     
			},			
			onInfinite() {
				console.log('onInfinite', this.vlCount, this.totalCount, this.loading)
				if (this.vlCount >= this.totalCount || this.loading) return

				this.page++
				this.get(this.page)
			},			
			async get(page) {
				page = page || 1
				let perPage = 100
				let url = apiUrl + '/orders/aggregate/search'
					url += '?token=' + window.token
					url += '&perPage=' + perPage
					url += '&page=' + page 
				
				if (this.searchBy == 'date')
					url += `&start=${this.start}&end=${this.end}`

				if (this.searchBy == 'user email') 
					url += `&userEmail=${this.email}`

				this.loading = true

				let res = await f7.request.promise.json(url)
				res = res.data 
				let list = res.orders || []
				this.loading = false

				if (!list.length) {
					f7.dialog.close()
					return f7.dialog.alert('No Results Found')
				}

				this.totalCount = res.totalCount || 0
				if (list.length < perPage) this.totalCount = list.length

				list.forEach(item => {
					item.created = item.created.split('.')[0]
				})
			
				setTimeout(() => {
					this.updateVl(list)
				}, 1)
				f7.dialog.close()
			},
		},
		mounted() {
			this.regexHeaders()
		},
		computed: {
    		subtitle() {
    			if (!this.vlCount) return ''
    			
    			let items = this.vlData.items 
    			return items[0].key + items.length + ' of ' + this.vlCount + ' listed'
    		}
    	}
	}
</script>
<style lang="less">
@import "../css/common.less";
.orders {
	.reports-container {
		min-width: 2800px;
	}
	.list {
		.selected{
			.item-inner:after {
				height: 0;				
			}
		}
	}
}
</style>

