<template>
	<f7-list inset inline-labels no-hairlines-md>
		<f7-list-input
			v-if="searchOption"
			label="search by"
			:class="!searchBy ? 'selected' : ''"
			type="select"
			@input="onSearchType"
			:value="searchBy">
			<option selected disabled value="">Select an option</option>
			<option v-for="s in searchType" 
				:value="s">{{ s }}
			</option>
		</f7-list-input>

		<f7-list-input 
			v-show="(/email/i.test(searchBy))"
        	class="selected"
        	type="text"
            label="Email"
            placeholder="Enter Email"
            @input="onEmail"
            :value="email"/>

		<f7-list-input
			v-show="date || (/date/i.test(searchBy))"		
			label="date range"
			type="datepicker"
			:class="!dateRange.length ? 'selected' : ''"
			placeholder="Select Date Range"
			@calendarChange="onDateRange"
			:value="dateRange"
			:calendar-params="calendarParams"
			readonly/>

		<f7-list-input
        	v-if="countryFilter"
        	v-show="dateRange.length"
			label="country"
			type="select"
			@input="onCountry"
			:value="country"
			defaultValue="all">
			<option disabled value="">Select a Country</option>
			<option v-for="c in countries" 
				:value="c">{{ c == 'eu_jp' ? 'EU + Japan' : c }}
			</option>
		</f7-list-input>

		<f7-list-input
			v-if="platformFilter"
			v-show="dateRange.length"
			label="platform"
			type="select"
			@input="onPlatform"
			:value="platform"
			defaultValue="all">
			<option disabled value="">Select a Platform</option>
			<option v-for="p in platforms" 
				:value="p">{{ p }}
			</option>
		</f7-list-input>

		<f7-list-input
			v-if="appTypeFilter"
			v-show="dateRange.length"
			label="app type"
			type="select"
			@input="onAppType"
			:value="appType"
			defaultValue="all">
			<option disabled value="">Select a Type</option>
			<option v-for="a in appTypes" 
				:value="a">{{ a }}
			</option>
		</f7-list-input>
	</f7-list>
</template>
<script>
export default {
    props: {
    	searchOption: Boolean,
    	onSearchType: Function,
    	searchType: Array,
    	searchBy: String,

    	onEmail: {
            type: Function,        
            default: () => {}
        },
    	email: String,

    	date: Boolean,    	
    	onDateRange: Function,
    	dateRange: Array, 
    	calendarParams: Object,

    	countryFilter: Boolean,
    	onCountry: Function,
    	country: String,
    	countries: Array,

    	platformFilter: Boolean,
		onPlatform: Function,
		platform: String,
		platforms: Array,

		appTypeFilter: Boolean,
		onAppType: Function,
		appType: String,
		appTypes: Array,
    }
}
</script>