export default {		
	formatDuration: function (dur) {
		if (typeof dur == 'string' && /:/.test(dur)) return dur
			
		var hr = parseInt(dur / 3600)
		dur = dur % 3600
		var min = parseInt(dur / 60) % 60
		var sec = parseInt(dur % 60)
		dur = (min < 10 ? "0" + min : min) + ":" + (sec < 10 ? "0" + sec : sec)
		if (hr) dur = hr + ":" + (min < 10 ? "0" + min : min) + ":" + (sec < 10 ? "0" + sec : sec)
		return dur
	},
	diff: function (a1, a2) {
		return a1.filter(a => !a2.includes(a))
	},
	isValidEmail: function (email) {
		var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
		return pattern.test(email);
	},
	getQuery: function (query, field) {
	    const results = {}
	    query.replace(/^\?/,'').replace(/([^=&]+)=([^&]*)/gi, 
	        (m, key, value) => results[key] = decodeURIComponent(value))
	    if (!field) return results

	    return results[field]
    },
    camelCaseRegex: function (header) {
    	let rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g
    	return header.replace( rex, '$1$4 $2$3$5' )
    },
    showToast: function (text) {
    	f7.toast.create({
			text: text,
			position: 'center',
			closeTimeout: 3000
		}).open()
    },
}