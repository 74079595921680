<template>
	<f7-panel left reveal theme-dark>
		<f7-view>
			<f7-page class="navmenu">
				<div v-for="(h, index) in Object.keys(adminHeaders)"					
					:key="h">
					<f7-block-title >{{ h }}</f7-block-title>
					<f7-list>
						<f7-list-item 
							v-for="value in Object.values(adminHeaders)[index]"
							:key="value"
							:class="value"
							v-if="loginStatus && value != 'login'"
							link="#" 
							@click="tabClicked(value)" 
							panel-close 
							:title="value"/>
					</f7-list>
				</div>									
			</f7-page>
		</f7-view>
	</f7-panel>
</template>

<script>
	export default {
		data() {
			return {
				loginStatus: null,
				adminHeaders: {
					reports: ['streams', 'downloads', 'users', 'devices', 'orders'], 
					user: ['logout', 'login']
				},	
			}
		},
		methods: {
			tabClicked(id) {
				if (id != 'logout') {
					f7.tab.show('#view-' + id)
					$$(this.$el).find('.list a').removeClass('active-item')
					$$(this.$el).find('.list .' + id + ' a').addClass('active-item')
				}
				else {
					this.logout()
				}	
			},
			async logout() {
				f7.dialog.confirm('Are you sure you want to log out?', 'Log Out', () => {
		        	evm.$emit('userLogOut')
					this.loginStatus = null
				})
			}, 
			showTab() {
				setTimeout(() => {
					$$(`.navmenu .streams a`).click()
					f7.tab.show('#view-streams')
				}, 100)
			},
			getLoginStatus(status) {
				this.loginStatus = status

				setTimeout(() => {					
					if (window.token) {
						if (!this.validated) 
							this.showTab()						
						this.validated = true
					}
					else
						evm.$emit('openAuth0')
				}, 100)
			},
		},
		mounted() {
			evm.$on('loginStatus', this.getLoginStatus)
		}
	}
</script>
<style lang='less'>
@import "../css/common.less";

.navmenu {
	--f7-page-bg-color: darken(@theme, 38%);
	--f7-list-bg-color: darken(@theme, 35%);	    	
	--f7-list-item-border-color: darken(@theme, 32%);	  
	--f7-block-title-text-color: @white;
	--f7-block-title-font-weight: bold;
	--f7-list-item-title-text-color: @white;
	--f7-list-chevron-icon-color: darken(@theme, 20%);
	--f7-list-font-size: 14px;

	.block-title {
		text-transform: capitalize;		
	}
    .item-link {
	    &.active-state, &.active-item {
	        background: darken(@theme, 30%);	    	
	        .item-inner:after {
	            background: transparent;
	        }
	    }
	    &.active-item {
	        .item-inner .item-title {
	            opacity: 1;
	        }
	        border-left: 5px solid lighten(@theme, 20%);
	    }

	    border-left: 5px solid transparent;

        .item-inner .item-title {
	        opacity: 0.7;
        }
	}
}
</style>